import type { AnchorProps } from "@solidjs/router";
import { Show } from "solid-js";

import SmartA from "~/components/SmartA";

interface MaybeAProps extends Omit<AnchorProps, "href"> {
  readonly href?: string | null;
}

export default function MaybeA(props: MaybeAProps) {
  return (
    <Show when={props.href} fallback={props.children}>
      <SmartA {...props} href={props.href ?? ""} />
    </Show>
  );
}
